import { useEffect, useRef, useState } from 'react';
import { useInView } from "react-intersection-observer";
import './App.css';
import About from './components/About';
import Contact from './components/Contact';
import Hero from './components/Hero';
import Projects from './components/Projects';
import Sidebar from './components/Sidebar';
import Work from './components/Work';
import Navbar from './NavBar';
import CircleLoader from './components/CircleLoader';

//need to re look at Spotuf Profile class to see what logos n things the lady changed in the manifest and stuff, some of mine r messed up
//OTHER THINGS TO DO:
// 1. Make some underline animation for hovering and selecting links, esp the navbar ones
// 2. Use the refs i already have to make things appear as you scroll
// 3. maybe cycle thru the projects in a useeffect so they all load before user gets there?

function App() {
  const [active, setActive] = useState("home");
  const [activeIdx, setActiveIdx] = useState(0);
  const [imgsLoaded, setImgsLoaded] = useState(false);

  useEffect(() => {
    const IMAGES = [
     "/img/lineArt.png",
     "/img/newLogo.png",
     "/img/prof.JPG",
    ]
    const loadImage = image => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image()
        loadImg.src = image
        // wait 2 seconds to simulate loading time
        loadImg.onload = () =>
          setTimeout(() => {
            resolve(image)
          }, 2000)

        loadImg.onerror = err => reject(err)
      })
    }

    Promise.all(IMAGES.map(image => loadImage(image)))
      .then(() => setImgsLoaded(true))
      .catch(err => console.log("Failed to load images", err))
  }, [])

  const homeRef = useRef();
  const aboutRef = useRef();
  const workRef = useRef();
  const projectsRef = useRef();
  const contactRef = useRef();
  const [homeV, homeInView] = useInView({
    threshold: .5,
  })
  const [aboutV, aboutInView] = useInView({
    threshold: .5,
  })
  const [workV, workInView] = useInView({
    threshold: .5,
  })
  const [projectsV, projectsInView] = useInView({
    threshold: .5,
  })
  const [contactV, contactInView] = useInView({
    threshold: .5,
  })

  useEffect(() => {
      if(homeInView) {
        setActive("Home");
        setActiveIdx(0);
      } else if(aboutInView) {
        setActive("About");
        setActiveIdx(1);
      } else if(workInView) {
        setActive("Work");
        setActiveIdx(2);
      } else if(projectsInView) {
        setActive("Projects");
        setActiveIdx(3);
      } else if(contactInView) {
        setActive("Contact");
        setActiveIdx(6);
      }
  }, [homeInView, aboutInView, workInView, projectsInView, contactInView]);

  //CAN USE ONLOAD to set state of loading for each individual img, not quite what im looking for tho

  // useEffect(() => {
  //   const imgs = [
  //      "/img/rfSmall.png",
  //      "/img/hero-image.webp",
  //      "/img/lineArt.png",
  //      "/img/newLogo.png",
  //      "/img/prof.JPG",
  //      "/img/SpotifyProfile.png",
  //      "/img/white-movieNight2.png",
  //   ]

  //   cacheImages(imgs);
  // }, [])


  // const cacheImages = async (srcArray) => {
  //   const promises = await srcArray.map((src) => {
  //     return new Promise(function (resolve, reject) {
  //       const img = new Image();

  //       img.src = src;
  //       img.onload = resolve();
  //       img.onerror = reject();
  //     });
  //   });
  //   await Promise.all(promises);

  //   setIsLoading(false);
  // }

  //ALL COLORS USED NEED TO BE DEFINED IN tailwind.config.js

  return (
    <div className="App">
      {
        !imgsLoaded ? (     
        <div 
        style={{
          display: 'flex',
          flexDirection: "column",
          justifyContent: 'center',
          alignItems: "center"
        }}
        className='bg-midnight h-full'>
          <CircleLoader/>
        </div>
        ) : (
          <div
          className="bg-midnight text-white"
          >
            {/* {
              active === "home" ? null :
              <Sidebar 
              active={active}
              activeIdx={activeIdx}
              homeRef={homeRef}
              aboutRef={aboutRef} 
              callistoRef={callistoRef}
              cdkRef={cdkRef}
              recipeRef={recipeRef}
              movieRef={movieRef}
              contactRef={contactRef}/>
            } */}
              {/* <CircleLoader/> */}
              <Sidebar 
              active={active}
              activeIdx={activeIdx}
              homeRef={homeRef}
              aboutRef={aboutRef} 
              workRef={workRef}
              projectsRef={projectsRef}
              contactRef={contactRef}/>
            <Navbar
              active={active}
              activeIdx={activeIdx}
              homeRef={homeRef}
              aboutRef={aboutRef} 
              workRef={workRef}
              projectsRef={projectsRef}
              contactRef={contactRef}
            />        
            <Hero myView={homeV} myRef={homeRef} aboutRef={aboutRef}/>
            <About myView={aboutV} myRef={aboutRef}/>
            <Work myView={workV} myRef={workRef} />
            <Projects myView={projectsV} myRef={projectsRef} />
            <Contact myView={contactV} myRef={contactRef} />
          </div>
        )
      }
    </div>
  );
}

export default App;
