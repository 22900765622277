import React, { useEffect } from 'react';
import { useState } from 'react';
import ProjectItem from './ProjectItem';

const projs = [
    {
        name: "Spotify Profile",
        desc: <p>Currently in development, Spotify Profile is a web app that <span className='text-blue-accent'>displays your personalized Spotify data</span>. As a music lover, I wanted to gain more insight into my daily listening, without waiting for the year end Spotify Wrapped to tell me. Utilizing the <span className='text-blue-accent'>Spotify API</span>, you can view stats such as <span className='text-blue-accent'>top artists, songs, playlists, and filter them by time ranges </span> to get great data on your listening habits.</p>,
        img: "bg-spotify bg-center",
        techs: ["React", "Node", "Express"],
        appType: "Web App",
        links: ["https://github.com/khandy7/SpotifyProfile"]
    },
    {
        name: "Recipe Finder", 
        desc: <p>A web app to help you <span className='text-blue-accent'>find recipes that fit your needs</span>. Utilizing the <span className='text-blue-accent'>Spoonacular API</span>, this app allows you to <span className='text-blue-accent'>input ingredients</span> you have at home and will return you recipes that make use of them. <span className='text-blue-accent'>Search</span> by ingredients or by cuisine, <span className='text-blue-accent'>save recipes</span> for later, and <span className='text-blue-accent'>add friends</span> to see what they are making.</p>,
        img: "bg-recipe-finder bg-top",
        techs: ["React", "Node", "Express", "MongoDB"],
        appType: "Web App",
        links: ["https://github.com/khandy7/RecipeFinder"]
    },
    {
        name: "Movie Night",
        desc: <p>An Android app that helps you <span className='text-blue-accent'>find movies to watch</span>. Similar to Tinder but for movie buffs , <span className='text-blue-accent'>swipe to like or dislike countless films based on filters</span> like genre, top rated, trending now, and much more. Other features include <span className='text-blue-accent'>creating watchlists</span> and <span className='text-blue-accent'>adding friends </span>to see what they are watching.</p>,
        img: "bg-movie-night bg-bottom",
        techs: ["Flutter", "Firebase"],
        appType: "Android App",
        links: ["https://github.com/khandy7/MovieNight"]
    },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


function Projects({myRef, myView}) {
    const [active, setActive] = useState(0);

    useEffect(() => {
        for(let i = 1; i < projs.length; i++) {
            setActive(i);
            setTimeout(100);
        }
        setActive(0);
    }, [])

    return (
        <div 
        ref={myView}>
      <div 
      ref={myRef} 
      className="section">
                <div
                style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: "column",
                    alignItems: 'center',
                    justifyContent: 'center',
                    }}
                    className="my-10 mb-36"
                >
                    <div className='mb-12 mt-20 w-5/6 md:w-1/2 xl:w-1/4 text-3xl text-dark-gray'>
                    <div className="section-header">
                        <h1>Projects</h1>
                    </div>
                    </div>
                    <div 
                    style={{
                        fontFamily:"Verdana, Geneva, Tahoma, sans-serif"
                    }}
                    className='w-11/12 sm:w-5/6 md:w-3/4 lg:w-10/12 xl:w-8/12 m-auto flex flex-col text-light-gray'>
                    <div
                        className='flex m-auto'
                        >
                            {projs.map((ele, index) => (
                                <div 
                                key={index}
                                className={classNames(
                                    'personalFont mb-8 ml-2 p-2 md:p-4 text-sm transition duration-500 ease-in-out h-1/6 flex items-center border-2 border-transparent cursor-pointer hover:bg-light-midnight hover:text-blue-accent',
                                    active === index ? 'text-blue-accent bg-light-midnight border-b-blue-accent' : ' border-b-light-gray'

                                )}
                                onClick={() => setActive(index)}
                                >
                                    <p className='m-auto'>
                                        {ele.name}
                                    </p>
                                </div>
                            ))}
                        </div>
                    <div className='flex flex-col w-full md:w-2/3 m-auto'>
                            <ProjectItem projObject={projs[active]} />
                    </div>
                    </div>
                </div>
      </div>
      </div>
    );
  }
  
  export default Projects;