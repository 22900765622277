import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

function Sidebar({homeRef, aboutRef, workRef, projectsRef ,contactRef, active, activeIdx}) {
    return (
        <div className='w-1/12'>
            {/* <img
            alt='logo'
            className='w-28 pt-6 fixed hidden lg:block right-16 fade-in-image'
            src="/img/newLogo.png"/> NEED TO CHANGE LOGO HERE TO NEW COLOR */}
            <div className="hidden lg:block sidebar h-screen fixed p-2 mr-16 right-0 fade-in-text text-light-gray">
                {/* <div className='sidebarNav'>
                    <p onClick={() => homeRef.current.scrollIntoView()} className={active === "home" ? "sidebarNavItemSelected text-blue-accent" : "sidebarNavItem"}>Home</p>
                </div>
                <div className='sidebarNav'>
                    <p onClick={() => aboutRef.current.scrollIntoView()} className={active === "about" ? "sidebarNavItemSelected text-blue-accent" : "sidebarNavItem"}>About</p>
                </div>
                <div className='sidebarNav'>
                    <p onClick={() => workRef.current.scrollIntoView()} className={active === "work" ? "sidebarNavItemSelected text-blue-accent" : "sidebarNavItem"}>Work</p>
                </div>
                <div className='sidebarNav'>
                    <p onClick={() => projectsRef.current.scrollIntoView()} className={active === "projects" ? "sidebarNavItemSelected text-blue-accent" : "sidebarNavItem"}>Projects</p>
                </div>
                <div className='sidebarNav'>
                    <p onClick={() => contactRef.current.scrollIntoView()}  className={active === "contact" ? "sidebarNavItemSelected text-blue-accent" : "sidebarNavItem"}>Contact</p>    
                </div> */}
                <div className='mt-96 ml-2'>
                    <div className="sidebarNavItem ml-4">
                        <a target="_blank" rel="noreferrer" href="https://github.com/khandy7" className="hover:text-blue-accent transition ease-in-out"><FontAwesomeIcon className="sidebarNavIcon" icon={faGithub} />    </a>
                    </div>
                    <div className="sidebarNavItem ml-4">
                    <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/kyle-handy/" className="hover:text-blue-accent transition ease-in-out"><FontAwesomeIcon className="sidebarNavIcon" icon={faLinkedin} /></a>
                    </div>    
                    <div className="sidebarNavItem ml-4">
                    <a target="_blank" rel="noreferrer" href="mailto:kylehandy777@gmail.com" className="hover:text-blue-accent transition ease-in-out"><FontAwesomeIcon className="sidebarNavIcon" icon={faEnvelope} /></a> 
                    </div>    
                </div>
                <div
                className="border-l-2 mt-10 ml-10 h-full"
                >
                    
                </div>
            </div>
        </div>
    );
  }
  
  export default Sidebar;