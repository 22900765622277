import { Disclosure } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-scroll"
import Resume from "./Resume.pdf"

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navbar({homeRef, aboutRef, workRef, projectsRef ,contactRef, active, activeIdx}) {
    const navigation = [
      { name: 'Home', href: '/', current: false, ref: homeRef },
      { name: 'About', href: '/about', current: false, ref: aboutRef },
      { name: 'Work', href: '/Work', current: false, ref: workRef },
      { name: 'Projects', href: '/projects', current: false, ref: projectsRef },
      { name: 'Contact', href: '/contact', current: false, ref: contactRef },
    ]

    return (
      <nav className=" bg-midnight p-2 w-full sticky z-10 top-0">
            <div className="container mx-auto flex flex-wrap items-center">
    <Disclosure as="nav" className="transparent p-2 mt-0 w-full sticky z-10 top-0">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-0 lg:px-8">
            <div className="relative flex items-center justify-between h-6">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-gray-400 text-2xl">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <FontAwesomeIcon icon={faTimes} className='text-3xl'/>
                  ) : (
                    <FontAwesomeIcon icon={faBars}/>
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center">
                </div>
                <div className="hidden sm:block">
                  <div className="flex space-x-0 md:space-x-6">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.name} 
                        onClick={() => {
                          if(item.name === "Home") {
                            item.ref.current.style.scrollMargin = "100px";
                          } else {
                            item.ref.current.style.scrollMargin = "0px";
                          }
                          item.ref.current.scrollIntoView();
                        }}
                        spy={true} smooth={true}
                        className={classNames(
                          active === item.name ? 'text-blue-accent sidebarNavItemSelected' : 'hover:text-dark-gray',
                          'px-3 sm:py-3 lg:py-5 rounded-md text-sm md:text-md lg:text-lg font-medium cursor-pointer'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 -right-6 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              <a rel="noreferrer" href={Resume} target="_blank"><button className='personalFont px-3 h-10 mt-2 sm:mt-0 lg:mt-2 mr-2 rounded-md text-sm md:text-md lg:text-lg font-medium cursor-pointer border-2 border-blue-accent transition duration-500 ease-in-out transform  hover:scale-105 hover:bg-blue-accent/10 text-blue-accent'>Resume</button></a>
                <button className="w-24 p-2 rounded-md">
                  <Link to="Home" onClick={() => {
                    homeRef.current.style.scrollMargin = "100px";
                    homeRef.current.scrollIntoView();
                  }} spy={true} smooth={true}>            
                  <img
                  alt='logo'
                  className='pt-2 ml-2'
                  src="/img/newLogo.png"/></Link>
                </button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
          {({ close }) => (
              <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.name} 
                  onClick={() => {
                    if(item.name === "Home") {
                      item.ref.current.style.scrollMargin = "300px";
                    } else {
                      item.ref.current.style.scrollMargin = "250px";
                    }
                    item.ref.current.scrollIntoView();
                    close(item.ref);
                  }}
                  spy={true} smooth={true}
                  className={classNames(
                    active === item.name ? 'text-blue-accent sidebarNavItemSelected' : '',
                    'block px-3 py-2 rounded-md text-base cursor-pointer font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Link>
              ))}
            </div>
          )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
            </div>
        </nav>
    )
}