import React from 'react';
import Profile from './Profile';

function About({myRef, myView}) {
    return (
        <div 
        ref={myView}>
            <div 
            ref={myRef} 
            className="section">
                <div
                style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: "column",
                    alignItems: 'center',
                    justifyContent: 'center',
                    }}
                >
                    <div className='mt-16 md:mt-24 lg:mt-16 w-5/6 md:w-1/2 xl:w-1/4 text-3xl text-dark-gray'>
                    <div className="section-header">
                        <h1>About Me</h1>
                    </div>
                    </div>
                    <div className=''>
                        <Profile/>
                    </div>
                </div>
            </div>
        </div>
    );
  }
  
  export default About;