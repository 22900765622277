import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from "@fortawesome/free-solid-svg-icons"
import React, { useState } from 'react';

const experience = [
    //maybe make 'bulletPoints' instead of content, and can loop thru that list instead to make bullet points nice
    { name: 'Callisto Media', bulletPoints: [
            "Integrated into large codebase and developed code in a full stack environment. Technologies included C++, HTML, CSS, JavaScript, jQuery, and Python.",
            "Created backend tool to import metadata from spreadsheets into databases, saving the Product team hours in data input.",
            "Assisted in improving a back end job that pulls data in from multiple different APIs, increasing total data retrieval of this job by over 150%.",
            "Implemented several new front end features as defined and scoped by the Product team.",
            "Coordinated with Product Management and QA teams to ensure the highest quality of software possible."
        ],
        title: "Associate Software Engineer",
        dates: "February 2022 - October 2022",
    },
    { name: 'CDK Global',  bulletPoints: [
        "Researched and implemented script to migrate version control system websites from Bitbucket to GitHub to increase developer productivity.",
        "Automated migration of hundreds of production repositories using Python and git.",
        "Collaborated effectively with members of several software development teams.",
        "Documented technical workflow and knowledge for education of developers."
        ],
        title: "Software Engineer Intern",
        dates: "June 2021 - August 2021",
    },
    { name: 'CSU Chico',  bulletPoints: [
        "Assisted students in computer science classes with a variety of problems using many different technologies, as well as holding my own tutoring hours outside of class.",
        "Broke down and evaluated student problems, using personal expertise and probing questions.",
        "Gained hands on experience analyzing and problem-solving students' code.",
        "Worked closely with professors to create lectures and decide on project details."
        ],
        title: "Tutor/Lab Assistant",
        dates: "January 2020 - December 2021",
    },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


function Work({myRef, myView}) {
    const [active, setActive] = useState(0);

    return (
        <div 
        ref={myView}>
      <div 
      ref={myRef} 
      className="section">
                <div
                style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: "column",
                    alignItems: 'center',
                    justifyContent: 'center',
                    }}
                    className="my-24"
                >
                    <div className='mt-20 mb-12 w-5/6 md:w-1/2 xl:w-1/4 text-3xl text-dark-gray'>
                    <div className="section-header">
                        <h1>Work</h1>
                    </div>
                    </div>
                    <div
                    style={{
                        fontFamily:"Verdana, Geneva, Tahoma, sans-serif"
                    }}
                    className=' w-11/12 sm:w-5/6 md:w-3/4 lg:w-3/4 xl:w-7/12 m-auto flex flex-col text-light-gray '>
                        <div
                        className='flex m-auto h-full'
                        >
                            {experience.map((ele, index) => (
                                <div 
                                className={classNames(
                                    'personalFont mb-4 ml-2 p-2 md:p-4 text-sm transition duration-500 ease-in-out flex items-center border-2 border-transparent cursor-pointer hover:bg-light-midnight hover:text-blue-accent',
                                    active === index ? 'text-blue-accent bg-light-midnight border-b-blue-accent' : ' border-b-light-gray'

                                )}
                                key={index}
                                // className={"h-1/3 flex items-center border-2 border-blue-accent cursor-pointer "}
                                onClick={() => setActive(index)}
                                >
                                    <p className='m-auto'>
                                        {ele.name}
                                    </p>
                                </div>
                            ))}
                        </div>
                        <div className='flex flex-col w-full'>
                            <h1 className='flex ml-4 md:ml-8 text-dark-gray text-sm md:text-lg lg:text-lg xl:text-xl'>{experience[active].title} <span className='text-blue-accent'> &nbsp;@ {experience[active].name}</span></h1>
                            <p className='flex ml-4 md:ml-8 text-sm'>{experience[active].dates}</p>
                            <div className='p-4 relative text-left'>
                            {experience[active].bulletPoints.map(ele => (
                                <div key={ele} className='text-sm p-4 flex w-full'>
                                    <FontAwesomeIcon className='text-blue-accent mr-2 mt-1' icon={faCaretRight}/>
                                    <p>{ele}</p>
                                </div>
                            ))}
                            </div>
                        </div>
                    </div>
                </div>
      </div>
      </div>
    );
  }
  
  export default Work;