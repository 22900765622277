import React from 'react';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Hero({myRef, myView, aboutRef}) {
    const [classes, changeClasses] = useState("text-xl ml-2 transform transition duration-500 ease-in-out ");

    const newClasses = (classes) => {
        if (classes.includes("rotate-90")) {
            changeClasses(classes.substr(0, classes.length - 9))
        } else {
            changeClasses(classes.concat("rotate-90"))
        }
    }

    return (
        <div 
        ref={myView}>
            <div
            ref={myRef} 
            className="section">
                <div
                style={{
                    height: '90vh',
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: "left",
                    //padding: "0px 0px 0px 25%", //padding here is defining where on page all this is, will need to fix for each screen size
                    }}
                >
                    <div style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: 'center',
                    textAlign: "left",
                    }}
                    className="p-2 md:ml-4"
                    >
                        <p className='personalFont text-light-gray text-2xl ml-1 md:text-3xl mb-4'>Hi, my name is</p>
                        <h1 className='text-dark-gray text-6xl md:text-8xl mb-2 font-bold'>Kyle Handy.</h1>
                        <h2 className='mb-6 text-3xl md:text-5xl text-light-gray ml-1'>I'm a <span className='text-blue-accent'>Software Engineer</span></h2>
                        <p className='text-light-gray text-xl md:text-2xl mb-6 ml-1'>Currently seeking a <span className='text-blue-accent'>full-time position</span>, I specialize in creating things for the web.</p>
                        <button className='ml-1 personalFont mb-24 md:mb-12 text-lg w-60 h-14 text-center text-blue-accent border-2 border-blue-accent rounded-md transition duration-500 ease-in-out transform hover:scale-105 hover:bg-blue-accent/10'
                            onMouseEnter={() => {
                                newClasses(classes)
                            }}
                            onMouseLeave={() => {
                                newClasses(classes)
                            }}
                            onClick={() => {
                                aboutRef.current.style.scrollMargin = "0px";
                                aboutRef.current.scrollIntoView();
                            }}
                        >
                            Check out my work {} 
                        <FontAwesomeIcon icon={faArrowRight} className={classes}/>
                        </button>
                    </div>
                    <div
                    style={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: "column",
                        justifyContent: 'center',
                        }}
                    >
                        <img alt="Kyle Handy" className='hidden lg:block mb-24' src='/img/lineArt.png' />
                    </div>
                </div>
            </div>
      </div>
    );
  }
  
  export default Hero;