import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";

function Contact({myRef, myView}) {

    return (
      <div ref={myView}>
            <div
            ref={myRef} 
            className="section">
                <div
                style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: "column",
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontFamily:"Verdana, Geneva, Tahoma, sans-serif"

                    }}
                >
                    <div className='mt-16 w-5/6 md:w-1/2 xl:w-1/4 text-3xl text-dark-gray'>
                    <div className="section-header">
                        <h1>Contact</h1>
                    </div>
                    </div>
                    <div className='text-dark-gray text-5xl p-4 mb-6 mt-12 md:mt-20 lg:mt-26 lg:mb-12 xl:mb-16'>
                      Get In Touch!
                    </div>
                    <p className='text-lg text-light-gray w-3/4 md:w-1/2 lg:w-1/3 p-2'>I am currently seeking a <span className='text-blue-accent'>full-time position as a software engineer, so please contact me if you are interested</span> and I will get back to you as soon as I can. Thanks for taking the time to check out my website and I hope you have a great rest of your day.</p>
                    <a target="_blank" rel="noreferrer" href="mailto:kylehandy777@gmail.com" ><button className='personalFont mb-0 mt-12 border-blue-accent lg:mt-20 text-xl w-60 h-14 text-center text-blue-accent border-2 rounded-md transition duration-500 ease-in-out transform hover:scale-105 hover:bg-blue-accent/10'>
                      Email Me
                    </button></a>
                    <div className='block lg:hidden flex p-4 text-light-gray md:mt-20'>
                    <a target="_blank" rel="noreferrer" href="https://github.com/khandy7" className="hover:text-blue-accent transition ease-in-out p-4"><FontAwesomeIcon className="sidebarNavIcon" icon={faGithub} />    </a>
                    <a target="_blank" rel="noreferrer" href="mailto:kylehandy777@gmail.com" className='p-4 mt-6 hover:text-blue-accent transition ease-in-out cursor-pointer'><p>kylehandy777@gmail.com</p></a>
                    <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/kyle-handy/" className="hover:text-blue-accent transition ease-in-out p-4"><FontAwesomeIcon className="sidebarNavIcon" icon={faLinkedin} /></a>
                    </div>
                    <div className='mb-8 lg:mt-36 text-light-gray'>
                      Built by Kyle Handy using React
                    </div>
                </div>
                
            </div>
      </div>
    );
  }
  
  export default Contact;