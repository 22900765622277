import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';

function ProjectItem({projObject}) {
    return (
        <div 
        style={{
            // backgroundRepeat: "no-repeat",
            // backgroundImage: projObject.img,
            // backgroundSize: "contain",
            // backgroundPosition: "center"
        }}
        className={"w-full h-full flex bg-no-repeat bg-cover " + projObject.img}>
            <div className='bg-light-midnight opacity-90 w-full h-full'>
                <div className='text-left p-2 w-full'>
                    <h2 className='text-blue-accent mt-12  text-lg'>{projObject.appType}</h2>
                    <h1 className='text-dark-gray text-3xl mt-2 font-bold'>
                        {projObject.name}
                    </h1>
                    <div className='mt-6'>
                        {projObject.desc}
                    </div>
                    <div className='flex mt-6 mb-6'>
                        {projObject.techs.map((ele) => (
                            <div key={ele} className='mr-4 italic'>
                                {ele}
                            </div>
                        ))}
                    </div>
                    {
                        projObject.links 
                        ? 
                        <div>
                            {projObject.links.map((ele, idx) => (
                                <div key={idx} className='text-3xl lg:text-4xl ml-2 mb-6'>
                                    {
                                        idx === 0 ? <a className='transition transform ease-in-out hover:text-blue-accent hover:scale-105' href={ele} target="_blank" rel="noreferrer"  alt="GitHub Code Link">
                                            <FontAwesomeIcon icon={faGithub} />
                                            </a> : null
                                    }
                                </div>
                            ))}
                        </div>
                        : null
                    }
                </div>
            </div>
        </div>
    );
  }
  
  export default ProjectItem;