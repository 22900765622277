import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretRight } from "@fortawesome/free-solid-svg-icons"

export default function Profile() {

    return (

<div 
style={{
    fontFamily:"Verdana, Geneva, Tahoma, sans-serif"
}}
className="antialiased leading-normal tracking-wider">


<div className="max-w-4xl flex items-center lg:pt-0 h-auto flex-wrap mx-auto my-20 lg:my-0">
  
  <div id="profile" className="w-full lg:w-3/5 rounded-lg lg:rounded-l-lg lg:rounded-r-none transparent mx-6 lg:mx-0">
  

      <div className="p-4 lg:p-10 text-center lg:text-left">
          <div className="block lg:hidden rounded-lg shadow-4xl transition duration-500 ease-in-out hover:shadow-5xl mx-auto -mt-20 h-48 w-48 bg-cover bg-center" style={{ 
      backgroundImage: "url(./img/prof.JPG)" 
    }}></div> 
          {/* up size of text and limit amount */}
          <div className="mx-auto lg:mx-0 w-4/5 pt-4 border-b-2 border-light-gray opacity-25 mt-2"></div>
          <p className=" text-light-gray pt-4 md:pt-8 text-md lg:text-lg">Hello! My name is Kyle and I love programming and creating things for the internet. I graduated <span className="italic text-blue-accent">Magna Cum Laude</span> <span className="text-blue-accent">with a degree in Computer Science</span> from California State University, Chico in December 2021.</p>

          <p className=" text-light-gray pt-4 md:pt-8 text-md lg:text-lg">Since then, I have been honing my coding skills with <span className="italic text-blue-accent">professional experience, personal projects, and lots of LeetCode</span>. Currently, I am working on a web app to display personalized Spotify data.</p>
          <p className=" text-light-gray pt-4 md:pt-8 text-md lg:text-lg">When I'm not programming I love playing video games, cooking, playing with my cat Juno, hiking, and kicking around a soccer ball.</p>
          <p className=" text-light-gray pt-4 md:pt-8 text-md lg:text-lg">Here are a few technologies I have been working with recently:</p>
          <div className="personalFont grid grid-cols-3 gap-4 mt-2 text-light-gray">
            {/* Proabably should change these technologies used, but fine for now */}
            <div><FontAwesomeIcon className="text-blue-accent mr-2" icon={faCaretRight} />React</div>
            <div><FontAwesomeIcon className="text-blue-accent mr-2" icon={faCaretRight} />Node</div>
            <div><FontAwesomeIcon className="text-blue-accent mr-2" icon={faCaretRight} />Express</div>
            <div className="ml-2 lg:ml-0"><FontAwesomeIcon className="text-blue-accent mr-2" icon={faCaretRight} />HTML</div>
            <div className="mr-2"><FontAwesomeIcon className="text-blue-accent mr-2" icon={faCaretRight} />CSS</div>
            <div className="mr-6"><FontAwesomeIcon className="text-blue-accent mr-2" icon={faCaretRight} />Java</div>
          </div>
      </div>

  </div>
  
  <div className="w-full lg:w-1/4">
      <img src="/img/prof.JPG" alt="Kyle Handy" className="rounded-none lg:rounded-lg shadow-2xl hidden transition duration-500 ease-in-out lg:block lg:shadow-3xl hover:shadow-4xl"/>
  </div>
  

</div>  
</div>

    )
}
