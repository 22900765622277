import "./CircleLoader.css"
import { useState, useEffect } from "react";

function CircleLoader() {
    // const [show, setShow] = useState(false);

    // useEffect(() => {
    //   const interval = setInterval(() => {
    //     setShow((prevShow) => !prevShow);
    //   }, 2000);
  
    //   return () => clearInterval(interval);
    // }, []);

    return(
        // kinda wanna use polygon now, think it looks cooler
        //look into that
        <> 
            <svg className="circleLoader">
                <circle className="circle" cx="50%" cy="50%" r="70" />
                <text className="circleText" x="50%" y="50%" textAnchor="middle"  dy=".3em">KH</text>
            </svg>
        </>
    );
}

export default CircleLoader;